import clsx from 'clsx';
import { FieldWrapper } from './FieldWrapper';

export const FileInputField = (props) => {
  const { label, className, onChange, caption, disabled, registration, error, required, multiple } =
    props;

  return (
    <FieldWrapper label={label} error={error}>
      <div className="my-1 text-sm flex justify-start">{caption}</div>
      <input
        type="file"
        disabled={disabled}
        required={required}
        multiple={multiple}
        className={clsx(
          'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        onChange={onChange}
        {...registration}
      />
    </FieldWrapper>
  );
};
