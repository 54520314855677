import PropTypes from 'prop-types';
import * as React from 'react';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';
import { Bell, Lock } from 'react-feather';
import { Logo } from './components/Logo';
import { Link } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="bg-[#F8FAFE] flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative bg-[#142852] px-4 md:px-24 z-10 items-center flex-shrink-0 flex py-4 shadow">
          <Logo />

          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <Link
                to="/app/tutorials"
                className="flex flex-row items-center text-gray-100 space-x-2 mr-4 hover:underline"
              >
                <InformationCircleIcon className="h-4 w-4" />
                <span className="text-sm">Tutorials</span>
              </Link>
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {children}
          <div className="flex flex-col items-center justify-center space-y-2 mt-8">
            <div className="px-10 text-xs text-gray-800 truncate overflow-hidden">
              © {currentYear} Verdens Design Group. All rights reserved.
            </div>
          </div>
          <div className="h-12 flex items-center justify-center">
            <p className="text-center text-xs flex flex-row items-center space-x-1">
              <span>
                <span>Maintained by </span>{' '}
              </span>
              <a
                className="flex flex-row items-center bg-[#142852] p-1 px-2 rounded-full"
                href="https://github.com/jonahgeek"
                target="_blank"
                rel="noreferrer"
              >
                <Lock className="h-3 text-gray-100" />
                <span className="text-gray-100">Jonahgeek</span>
              </a>
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
