import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { SettingsRoutes } = lazyImport(() => import('features/settings'), 'SettingsRoutes');
const { TeamsRoutes } = lazyImport(() => import('features/teams'), 'TeamsRoutes');
const { ProjectsRoutes } = lazyImport(() => import('features/projects'), 'ProjectsRoutes');
const { ContactInfosRoutes } = lazyImport(
  () => import('features/contactInfo'),
  'ContactInfosRoutes'
);
const { AboutsRoutes } = lazyImport(() => import('features/about'), 'AboutsRoutes');
const { SocialsRoutes } = lazyImport(() => import('features/socials'), 'SocialsRoutes');
const { FeedbacksRoutes } = lazyImport(() => import('features/feedback'), 'FeedbacksRoutes');
const { PagesRoutes } = lazyImport(() => import('features/pages'), 'PagesRoutes');
const { DesignsRoutes } = lazyImport(() => import('features/designs'), 'DesignsRoutes');
const { OrdersRoutes } = lazyImport(() => import('features/orders'), 'OrdersRoutes');
const { TutorialsRoutes } = lazyImport(() => import('features/tutorials'), 'TutorialsRoutes');
const { ProjectTypesRoutes } = lazyImport(
  () => import('features/projectTypes'),
  'ProjectTypesRoutes'
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/my-account', element: <Profile /> },
      { path: '/app/projects/*', element: <ProjectsRoutes /> },
      { path: '/app/designs/*', element: <DesignsRoutes /> },
      { path: '/app/tutorials/*', element: <TutorialsRoutes /> },
      { path: '/app/teams/*', element: <TeamsRoutes /> },
      { path: '/app/orders/*', element: <OrdersRoutes /> },
      { path: '/app/settings/*', element: <SettingsRoutes /> },
      { path: '/app/contact-info/*', element: <ContactInfosRoutes /> },
      { path: '/app/about/*', element: <AboutsRoutes /> },
      { path: '/app/feedback/*', element: <FeedbacksRoutes /> },
      { path: '/app/pages/*', element: <PagesRoutes /> },
      { path: '/app/socials/*', element: <SocialsRoutes /> },
      { path: '/app/project-types/*', element: <ProjectTypesRoutes /> },
      { path: '/app/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
