import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useEffect } from 'react';
import { Archive, AtSign, Box, File, Info, Smile, Twitter, Users } from 'react-feather';
import { CiBoxList } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import storage from 'utils/storage';

export const Dashboard = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (user.role !== 'ADMIN') {
      storage.clearToken();
      window.location.assign('/');
    }
  }, [user]);

  const Card = ({ title, value, icon, url }) => {
    return (
      <div className="bg-white shadow rounded-lg p-4">
        <div className="flex flex-row items-center">
          <div className="p-3 text-[#142852]">{icon}</div>
          <div className="text-xl font-semibold ml-2">{title}</div>
        </div>
        <hr />
        <div className="text-sm my-4">{value}</div>
        <button
          onClick={() => navigate(url)}
          className="border border-blue-950 text-blue-950 p-2 w-full hover:bg-blue-950 hover:text-white rounded-lg"
        >
          Go to {title}
        </button>
      </div>
    );
  };

  return (
    <ContentLayout>
      <div className="text-md">Welcome,</div>
      <div className="text-2xl mb-8">{user.name || user.username} 😎</div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card
          title="Orders"
          url="/app/orders"
          value="Manage your orders"
          icon={<Archive size={20} />}
        />
        <Card
          title="Projects"
          url="/app/projects"
          value="Manage client projects"
          icon={<Box size={20} />}
        />
        <Card
          title="My Designs"
          url="/app/designs"
          value="Manage designs you created."
          icon={<CiBoxList size={20} />}
        />
        <Card
          title="About Info"
          url="/app/about"
          value="Manage information about Verdens Design Group."
          icon={<Info size={20} />}
        />
        <Card
          title="Team"
          url="/app/teams"
          value="Manage your teammates"
          icon={<Users size={20} />}
        />
        <Card
          title="Contact Info Module"
          url="/app/contact-info"
          value="Manage the content of your contact us page & your newsletter."
          icon={<AtSign size={20} />}
        />
        <Card
          title="Client Feedback"
          url="/app/feedback"
          value="Manage client feedback"
          icon={<Smile size={20} />}
        />
        <Card
          title="Page Management"
          url="/app/pages"
          value="Manage the content of your pages."
          icon={<File size={20} />}
        />
        <Card
          title="Socials"
          url="/app/socials"
          value="Manage your social media pages."
          icon={<Twitter size={20} />}
        />
      </div>
    </ContentLayout>
  );
};
