import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col justify-center sm:flex-row">
        <div className="sm:w-1/2 flex flex-col justify-center py-4 sm:px-6 lg:px-8">
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                  <Link className="flex items-center justify-center text-white" to="/">
                    <img className="h-12 w-auto" src={logo} alt="Verdens" />
                  </Link>
                </div>

                <h2 className="mt-3 text-start text-xl font-extrabold text-gray-900 dark:text-white">
                  {title}
                </h2>
              </div>
              {children}
              <div className="mt-4 text-sm">
                © 2024 | Verdens Design Group. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
